"use client";

import { useSession } from "next-auth/react";
import React, { ReactNode, createContext, useContext } from "react";

interface UserRoleContextProps {
  userRole: string | null;
  isAllowed: (role: string) => boolean;
}

const UserRoleContext = createContext<UserRoleContextProps | undefined>(
  undefined
);

interface UserRoleProviderProps {
  children: ReactNode;
}

export const UserRoleProvider = ({ children }: UserRoleProviderProps) => {
  const { data: session } = useSession();
  const userRole = session?.user.role || null;

  const isAllowed = (role: string) => {
    return userRole === role;
  };

  return (
    <UserRoleContext.Provider value={{ userRole, isAllowed }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useRoleContext = () => {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error("useRoleContext must be used within an UserRoleProvider");
  }
  return context;
};
