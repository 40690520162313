"use client";

import React, { ReactNode, createContext, useContext, useState } from "react";

interface SidebarContextProps {
  isOpen: boolean;
  toggle: () => void;
  handleToggle: () => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined
);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [ststus, setStatus] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  const handleToggle = () => {
    setStatus(true);
    toggle();
    setTimeout(() => setStatus(false), 500);
  };

  return (
    <SidebarContext.Provider value={{ isOpen, toggle, handleToggle }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
