"use client";

import { env } from "@/env";
import { useAccessControl } from "@/hooks/accessFeatureContext";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

interface PHPProviderProps {
  children: React.ReactNode;
}

export function PHProvider({ children }: PHPProviderProps) {
  const { enableFeatureFlags } = useAccessControl();
  // const posthogFlags = flags.reduce<Record<string, boolean>>((acc, flag) => {
  //   acc[flag] = true;
  //   return acc;
  // }, {});

  useEffect(() => {
    posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
      person_profiles: "identified_only",
      capture_pageview: false,
      capture_pageleave: true,
    });
  }, []);

  posthog.onFeatureFlags((flags) => {
    console.log("Feature flags", flags);
    enableFeatureFlags();
  });

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
