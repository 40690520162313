"use client";

import PostHogClient from "@/app/posthog";
import { useRouter } from "next/navigation";
import posthog from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export type AccessFlag = string;

export interface AccessControlContextType {
  isFlagEnabled: (flag: AccessFlag) => boolean;
  enableFeatureFlags: () => void;
  unauthorizedFallback: React.ReactNode;
  isLoading: boolean;
}

export interface AccessControlProviderProps {
  children: React.ReactNode;
  unauthorizedFallback?: React.ReactNode;
}

export interface WithAccessProps {
  requiredFlag: AccessFlag;
  fallback?: React.ReactNode;
  children: React.ReactNode;
  loadingFallback?: React.ReactNode;
  redirectPath?: string;
}

const AccessControlContext = createContext<AccessControlContextType | null>(
  null
);

export function AccessControlProvider({
  children,
  unauthorizedFallback = <div>Access denied</div>,
}: AccessControlProviderProps) {
  const [areFlagsEnabled, setAreFlagsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleFlagsLoaded = () => {
      setAreFlagsEnabled(true);
      setIsLoading(false);
    };

    if (posthog.getFeatureFlag("testFlag") !== undefined) {
      handleFlagsLoaded();
    } else {
      posthog.onFeatureFlags(handleFlagsLoaded);
    }
  }, []);

  const isFlagEnabled = (flag: AccessFlag) => {
    if (!areFlagsEnabled) return false;
    const flagValue = posthog.getFeatureFlag(flag);
    return Boolean(flagValue);
  };

  const enableFeatureFlags = () => {
    setAreFlagsEnabled(true);
  };

  const contextValue = useMemo(
    () => ({
      enableFeatureFlags,
      isFlagEnabled,
      unauthorizedFallback,
      isLoading,
    }),
    [unauthorizedFallback, isLoading]
  );

  return (
    <AccessControlContext.Provider value={contextValue}>
      {children}
    </AccessControlContext.Provider>
  );
}

export function useAccessControl() {
  const context = useContext(AccessControlContext);
  if (!context) {
    throw new Error(
      "useAccessControl must be used within an AccessControlProvider"
    );
  }
  return context;
}

export function WithAccess({
  requiredFlag,
  fallback,
  children,
  loadingFallback = <></>,
  redirectPath,
}: WithAccessProps) {
  const flagEnabled = useFeatureFlagEnabled(requiredFlag);
  const { unauthorizedFallback, isLoading } = useAccessControl();

  if (isLoading) {
    return <>{loadingFallback}</>;
  }

  if (!flagEnabled) {
    if (redirectPath) {
      const router = useRouter();
      router.push(redirectPath);
    }
    return <>{fallback || unauthorizedFallback}</>;
  }

  return <>{children}</>;
}
