import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/components/providers/postHogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/providers/reactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/providers/sessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccessControlProvider"] */ "/vercel/path0/hooks/accessFeatureContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserRoleProvider"] */ "/vercel/path0/hooks/userContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/vercel/path0/hooks/useSidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/vercel/path0/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
