"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FC, useState } from "react";

interface ReactQueryWrapperProps {
  children: React.ReactNode;
}

const ReactQueryWrapper: FC<ReactQueryWrapperProps> = ({ children }) => {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryWrapper;
